import React from "react";
import Header from "../components/sections/Header";
import ActionCallSection from "../components/sections/ActionCallSection";
import FeaturesSection from "../components/sections/FeaturesSection";
import FeatureItem from "../components/elements/FeatureItem";
import PageTitle from "../components/elements/PageTitle";
import RatesSection from "../components/sections/RatesSection";
import RateItem from "../components/elements/RateItem";
import BenefitsSection from "../components/sections/BenefitsSection";
import BenefitItem from "../components/elements/BenefitItem";
import ShortContactSection from "../components/sections/ShortContactSection";
import ServicesSection from "../components/sections/ServicesSection";
import ServiceItem from "../components/elements/ServiceItem";
import SupportSection from "../components/sections/SupportSection";
import MonitoringSection from "../components/sections/MonitoringSection";
import LearningSection from "../components/sections/LearningSection";
import AboutCompanySection from "../components/sections/AboutCompanySection";
import PeopleItem from "../components/elements/PeopleItem";
import ContactsSection from "../components/sections/ContactsSection";
import Footer from "../components/sections/Footer";
import CommonSection from "../components/sections/CommonSection";

const indexEnPage = () => (
  <>
    <PageTitle lang="en" title="Home" description={``} />
    <Header lang="en" />
    <ActionCallSection
      titleHighlited={`lite`}
      title={`.network`}
      description={`Мы предоставляем полный  комплекс услуг по настройке хостинга, поддержке и мониторингу веб-сервисов.`}
      firstBtnText={`Начать сейчас`}
      firstBtnPath={`/en/#contacts`}
      secondBtnText={`Все услуги`}
      secondBtnPath={`/en/#services`}
    >
      <FeaturesSection>
        <FeatureItem title="Гарантия" description="100%" />
        <FeatureItem title="Мониториг" description="24/7" />
        <FeatureItem title="года на рынке" description="с 2017" />
      </FeaturesSection>
    </ActionCallSection>
    <RatesSection
      title="Выберите лучший тариф"
      description="Во всех тарифных планах предусмотрена установка Kubernetes. Переходы между пакетами возможны, но в зависимости от начальной конфигурации и хостинг провайдера, могут потребовать дополнительные часы технической поддрежки и обговариваются отдельно."
      linkText="Подробнее о тарифах"
      linkPath="/en/rates/"
    >
      <RateItem
        title="Профессиональное сопровождение"
        price="8 000 ₽"
        frequency="/мес"
        description="Рекомендуется для небольших проектов, где не предъявляются высокие требования к непрерывности работы."
        btnText="Выбрать"
        btnPath="/en/#contacts"
        features={[
          "Развертывание на одном сервере",
          "Круглосуточный мониторинг сервера",
          "Телеграмм бот, информирующий о проблемах",
          "Live-chat c технической поддержкой",
          "Резервное копирование",
          "Инфраструктура как код IaC",
          "Доступы к логам",
          "Проактивное решение проблем инфраструктуры",
          "Время восстановления до 1 рабочего дня",
        ]}
      />
      <RateItem
        title="Профессиональное сопровождение с отказоустойчивостью"
        price="30 000 ₽"
        frequency="/мес"
        description="Рекомендуется для критически важных систем с высокими требованиями к непрерывности работы."
        isBetterText="Самый популярный"
        btnText="Выбрать"
        btnPath="/en/#contacts"
        features={[
          "Отказоустойчивый кластер на 3 серверах с балансировщиком нагрузки",
          "Компенсация за простой инфраструктуры",
          "Время восстановления 1 минута",
          "1 час работ технической поддержки",
          "Отработка методики переключения на резервный сервер",
          "Применение автоматических алгоритмов отказоустойчивости",
        ]}
      />
      <RateItem
        title="Профессиональное сопровождение с отказоустойчивостью с кастомизацией"
        price="от 40 000 ₽"
        frequency="/мес"
        description="Рекомендуется там, где есть особые требования к настройке инфраструктуры"
        btnText="Выбрать"
        btnPath="/en/#contacts"
        features={[
          "Вариант аналогичен профессиональному сопровождению с отказоустойчивостью",
          "Неограниченная инфраструктура",
          "2 часа технической поддержки",
          "Расширенная отработка методики переключения на резервные мощности",
          "Дополнительное резервирование объектов инфраструктуры до 3 объектов",
          "Репликация базы данных",
        ]}
      />
    </RatesSection>
    <BenefitsSection
      title="Почему выбирают нас?"
      description="Мы - команда единомышленников и у нас работают лучшие кадры. Благодаря высоким требованиям и профессиональному развитию сотрудников каждый наш клиент получит качественное обслуживание серверов. Опытные специалисты помогут подобрать выгодное предложение."
    >
      <BenefitItem
        title="Удобно"
        features={[
          "live-chat со службой техподдержки",
          "выделенный аккаунт-менеджер",
          "телеграм бот и grafana account для мониторинга",
          "неограниченность в технологиях",
          "время реакции на запросы заказчика и возникающие инциденты 2 часа",
        ]}
      />
      <BenefitItem
        title="Надежно"
        features={[
          "dev сервер “под ключ” на европейской площадке",
          "резервное копирование",
          "круглосуточный мониторинг",
        ]}
      />
      <BenefitItem
        title="Выгодно"
        features={[
          "фиксированный SLA",
          "компенсация за простой, рассчитанная как пятикратная сумма платежей компании LiteNetwork за время простоя",
          "решение проблем до полного устранения без дополнительных платежей",
          "фикс-прайс на любые доработки - 3000 руб/час, с тарификацией по 15 минут",
          "единая стоимость для всех услуг",
          "единовременная оплата настройки всех метрик",
        ]}
      />
    </BenefitsSection>
    <ShortContactSection
      title={`Подбор индивидуального плана`}
      description=""
      subjectMail="Discuss subject"
      emailLabel="E-mail"
      submitButton="Обсудить проект"
      submitButtonSuccess="Отправлено"
      alertTitle="Thank you!"
      alertText="We`ve received your message. Someone from our team will contact you soon"
    />
    <CommonSection
      title="Услуги"
      description="Проводим настройку и установку веб-сервисов и оборудования. Оперативно исправляем выявленные дефекты."
    >
      <SupportSection
        title="Поддержка"
        description="Комплекс задач, направленных на обеспечение стабильной работы и обслуживание всех составляющих веб-сервиса и среды исполнения."
        imgName="main.jpg"
      />
      <MonitoringSection
        title="Мониторинг"
        description="Наблюдение за показателями веб-сервисов. Своевременное реагирование на сбои и устранение последствий неполадок."
        imgName="man_cube.png"
      />
      <LearningSection
        title="Учение"
        imgName="robot_phone.png"
        feeds={[
          "Предварительная разработка плана восстановления",
          "Оперативное исправление дефектов среды исполнения в случае сбоя",
          "Возврат к актуальному состоянию",
        ]}
      />
    </CommonSection>
    <ServicesSection
      title="Настройка и установка"
      description=""
      columnTitles={[
        "Расценки на стандартные работы в рамках дополнительных услуг",
        "Руб",
      ]}
    >
      {/* write / at the beginning of the phrase if you want to make a tab */}
      <ServiceItem description="Заворачивание в docker:" criterion="" />
      <ServiceItem description="/по шаблону" criterion="12000" />
      <ServiceItem description="/простой сервис" criterion="24000" />
      <ServiceItem description="/сложный сервис" criterion="от 24000" />
      <ServiceItem
        description="Составление docker-compose в дополнение к заворачиванию в docker за 1 микросервис при наличии всей необходимой документации"
        criterion="3000"
      />
      <ServiceItem
        description="Настройка CI/CD на базе gitlab CI и docker-compose"
        criterion=""
      />
      <ServiceItem
        description="/монолит, в зависимости от требований заказчика"
        criterion="от 18000"
      />
      <ServiceItem
        description="/микросервисная архитектура, за микросервис"
        criterion="12000"
      />
      <ServiceItem
        description="настройка nginx reverse proxy, за 1 микросервис"
        criterion="3000"
      />
      <ServiceItem
        description="настройка ELK в объеме:"
        criterion=""
      />
      <ServiceItem
        description="/установка на мощности заказчика"
        criterion="12000"
      />
      <ServiceItem
        description="/1 визуализация"
        criterion="3000"
      />
      <ServiceItem
        description="настройка Grafana и Prometheus в объеме:"
        criterion=""
      />
      <ServiceItem
        description="/1 базовая метрика"
        criterion="1500"
      />
      <ServiceItem
        description="/кастомная метрика"
        criterion="от 3000"
      />
      <ServiceItem
        description="Услуги с тарификацией по 15 минут"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/Базовое системное администрирование с тарификацией по 15 минут"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/Конфигурирование новых серверов и т.п."
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/аудит инфраструктуры"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/оптимизация инфраструктуры"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/мастер-классы для сотрудников"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/переезд на другие площадки дата-центров без ограничения по времени"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/создание стратегии обеспечения непрерывности бизнеса, анализ возможных мест аварий и устранение их для процессов с составленными DRP"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/увеличение отказоустойчивости и производительности баз данных"
        criterion="3000 руб/час"
      />
      <ServiceItem
        description="/пероиодические учения по переключении работы проекта с основного сервера на резервный"
        criterion="25000 руб за 1 обучение"
      />
      <ServiceItem
        description="/выездные встречи технических специалистов и менеджеров"
        criterion="обсуждается"
      />
    </ServicesSection>
    <AboutCompanySection title="О компании" description="Текст о компании">
      <PeopleItem name="Кирилл Ковальчук" imgName="main.jpg" role="директор" />
      <PeopleItem name="Андрей Горлов" imgName="main.jpg" role="должность" />
      <PeopleItem name="Тимур" imgName="main.jpg" role="технический специалист" />
      <PeopleItem name="Денис" imgName="main.jpg" role="специалист поддержки" />
    </AboutCompanySection>
    <ContactsSection
      title="Готовы обсудить проект?"
      description="Оставьте заявку онлайн, и мы обязательно свяжемся с вами в ближайшее время."
      subjectMail="Contact us"
      firstNameLabel="Имя"
      emailLabel="Email"
      phoneLabel="Телефон"
      messageLabel="Сообщение"
      submitButton="Отправить"
      submitButtonSuccess="Отправлено"
      alertTitle="Thank you!"
      alertText="We`ve received your message. Someone from our team will contact you soon"
    />
    <Footer lang="en" />
  </>
);

export default indexEnPage;
